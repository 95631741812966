#projects { 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-y: scroll;
}

.hidden {
    background-color: #FFF !important;
}

#projects-content {
    width: 100%;
    height: 100%;
    position: absolute;
    box-shadow: inset 0 0 10px 10px #FF;
}

#projects .project {
	width: 550px;
	background-color: #383737;
	color: #CCCCCC;
    margin: 5px 5px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
}
#projects .project-body {
	padding: 20px 30px;
	flex-grow: 1;
}
#projects .project img {
	width: 100%;
	border-radius: 5px;
}

#projects ul {
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	align-self: flex-end;
    padding: 20px 30px;
	color: #383737;
	opacity: 0.5;
}

#projects li {
	float: left;
	padding: 3px 8px;
	margin: 0px 5px 0px 0px;
	border: 1px solid #333333;
	background-color: #CCCCCC;
}	

@media screen and (max-width: 1780px) {
    #projects {
        justify-content: center;
    }
}
