#blog {
    background-color: #e0ffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.post {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    margin-bottom: 20px;
    cursor: pointer;
}

.selected_post {
    white-space: pre-line;
    overflow-y: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.selected_post button {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 4px 5px;
    background-color: #333333;
    color: #eeeeee;
    border: none;
    border-radius: 3px;
    width: 200px;
    align-self: center;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .selected_post {
        width: 100%;
    }
}

@media screen and (max-width: 680px) {
    .post {
        width: 100%;
    }
}
