@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@-webkit-keyframes slide-border {
    from { width: 0%; }
    to { width: 40%; }
}

@keyframes slide-border {
    from { width: 0%; }
    to { width: 40%; }
}

body, html, #root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: #111111;
    overflow: hidden;
}

#main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: middle;
    color: #AAAAAA;
}

#main-top, #main-bottom {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
}

#main-bottom {
    align-self: end;
    justify-content: flex-start;
    transform-origin: 100% 0%;
}

#main-top {
    justify-content: flex-end;
}

#main-top:after, #main-bottom:before {
    content: "";
    display: block;
    width: 0%;
    -webkit-animation: slide-border 2s linear 0s forwards;
            animation: slide-border 2s linear 0s forwards;
    position: absolute;
}

#main-top:after {
    align-self: start;
    border-bottom: 1px solid #222222;
}

#main-bottom:before {
    align-self: end;
    border-top: 1px solid #222222;
}


.title-font {
    margin: 0;
    font-family: 'Crimson Text', serif;
}

.name-bottom {
    transform: translate(0, -50%);
}

.name-top {
    transform: translate(0, 50%);
}

.slidecontainer1 {
    position: absolute;
    top: 100px;
}
.slidecontainer2 {
    position: absolute;
    top: 150px;
}
.slidecontainer3 {
    position: absolute;
    top: 200px;
}

#about {
    background-color: #e0ffff;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
}

#about-content-container {
    width: 750px;
    height: 65%;
}

#profile-picture-container {
    width: 100%;
    height: 40%; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

#profile-picture {
    height: 100%;
    border: 5px solid #555;
    border-radius: 50%;
    box-shadow: 0px 0px 10px #777;
}

@media screen and (max-width: 850px) {
    #about-content-container {
        width: 100%;
        overflow: scroll;
    }
}   

#projects { 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-y: scroll;
}

.hidden {
    background-color: #FFF !important;
}

#projects-content {
    width: 100%;
    height: 100%;
    position: absolute;
    box-shadow: inset 0 0 10px 10px #FF;
}

#projects .project {
	width: 550px;
	background-color: #383737;
	color: #CCCCCC;
    margin: 5px 5px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
}
#projects .project-body {
	padding: 20px 30px;
	flex-grow: 1;
}
#projects .project img {
	width: 100%;
	border-radius: 5px;
}

#projects ul {
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	align-self: flex-end;
    padding: 20px 30px;
	color: #383737;
	opacity: 0.5;
}

#projects li {
	float: left;
	padding: 3px 8px;
	margin: 0px 5px 0px 0px;
	border: 1px solid #333333;
	background-color: #CCCCCC;
}	

@media screen and (max-width: 1780px) {
    #projects {
        justify-content: center;
    }
}

#experience {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.company {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 18px;
    margin: 10px 0;
}

.company-header {
    display: flex;
    margin: 8px 0;
    font-weight: bold;
}

.company-name {
    flex-grow: 1;
}

.company-body li {
    font-size: 9px;
    margin: 3px 0;
}

.company-body li span {
    font-size: 18px;
}

@media screen and (max-width: 1600px) {
    #experience {
        overflow-y: scroll;
        justify-content: flex-start;
    }
}   

@media screen and (max-width: 1000px) {
    .company {
        width: 100%;
    }
}

@media screen and (max-width: 720px) {
    .company-header {
        display: block;
    }

    .company-name {
        width: 100%;
    }

    .company-date {
        width: 100%;
    }
}

#blog {
    background-color: #e0ffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.post {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    margin-bottom: 20px;
    cursor: pointer;
}

.selected_post {
    white-space: pre-line;
    overflow-y: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.selected_post button {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 4px 5px;
    background-color: #333333;
    color: #eeeeee;
    border: none;
    border-radius: 3px;
    width: 200px;
    align-self: center;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .selected_post {
        width: 100%;
    }
}

@media screen and (max-width: 680px) {
    .post {
        width: 100%;
    }
}

@-webkit-keyframes slide-border {
    from { width: 0%; }
    to { width: 40%; }
}

@keyframes slide-border {
    from { width: 0%; }
    to { width: 40%; }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html, #root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: #111111;
    overflow: overlay;
}

.underline {
    text-decoration: underline;
}

.emphasis {
    font-weight: bold;
}

a {
    text-decoration: none;
    color: inherit;
}

#main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #AAAAAA;
}

/* HOME */

#main-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}



.title-font {
    margin: 0;
    font-family: 'Lato', sans-serif;
}


#content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222222;
    flex-grow: 1;
    position: relative;
    width: 100%;
    padding: 50px 30px;
    height: 70%;

}

#content-container span {
    position: relative;
}

.container {
    width: 100%;
    height: 100%;
    position: relative;
}


#experience {
    background-color: #FFFFFF;
    color: #111111;
}

#projects {
    background-color: #FFFFFF;
    color: #111111;
}

.stylized-border:before, .stylized-border:after, #name:before, #name:after {
    display: block;
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
}

.stylized-border:before {
    bottom: 0px;
    left: -20px;
    border-bottom: 5px solid #222222;
    border-left: 5px solid #222222;
}

.stylized-border:after {
    top: 0;
    right: -20px;
    border-right: 5px solid #222222;
    border-top: 5px solid #222222;
}

.stylized-border {
    text-align: center;
}

#name:before {
    top: 0px;
    left: -20px;
    border-top: 5px solid #222222;
    border-left: 5px solid #222222;
}

#name:after {
    bottom: 0;
    right: -20px;
    border-right: 5px solid #222222;
    border-bottom: 5px solid #222222;
}

#name {
    padding: 5px 20px;
    text-shadow: 0px 0px 5px #FFFFFF;
    font-size: 100px;
}

/* NAVBAR */

#navbar-container {
    width: 100%;
    padding: 10px 30px;
    display: flex;
}


ul#navbar {
    display: flex;
    justify-content: space-between;
}

ul#navbar li {
    display: inline;
    padding: 0px 10px;
    padding-bottom: 5px;
    cursor: pointer;
    mix-blend-mode: difference;
}


ul#navbar #navbar_selected {
    border-bottom: 2px solid #CCCCCC;
    color: #CCCCCC;
}

#navbar-container .icon {
    display: none;
}

#logo {
    flex-grow: 1;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}


@media screen and (max-width: 1000px) {
    .stylized-border:before, .stylized-border:after, #name:before, #name:after {
        display: block;
        content: "";
        width: 0px;
        height: 0px;
        display: none;
        position: absolute;
    }

    #name {
        font-size: 10vw;
    }

    h1#name {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: middle;
    }
}   

@media screen and (max-width: 500px) {

    ul#navbar li:not(:first-child) { 
        display: none;
    }

    #navbar-container .icon {
        position: absolute; 
        right: 40px;
        top: 35px;
        display: block;
        cursor: pointer;
        z-index: 999999;
    }
    
    .display {
        flex-direction: column;
        z-index: 999999;
        width: 100%;
        margin-top: 30px;
    }

    .display li:not(:first-child) { 
        display: block !important;
        margin: 5px 0;
    }

    #main-overlay {
        padding: 0px;
    }

}



