#experience {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.company {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 18px;
    margin: 10px 0;
}

.company-header {
    display: flex;
    margin: 8px 0;
    font-weight: bold;
}

.company-name {
    flex-grow: 1;
}

.company-body li {
    font-size: 9px;
    margin: 3px 0;
}

.company-body li span {
    font-size: 18px;
}

@media screen and (max-width: 1600px) {
    #experience {
        overflow-y: scroll;
        justify-content: flex-start;
    }
}   

@media screen and (max-width: 1000px) {
    .company {
        width: 100%;
    }
}

@media screen and (max-width: 720px) {
    .company-header {
        display: block;
    }

    .company-name {
        width: 100%;
    }

    .company-date {
        width: 100%;
    }
}
