#about {
    background-color: #e0ffff;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
}

#about-content-container {
    width: 750px;
    height: 65%;
}

#profile-picture-container {
    width: 100%;
    height: 40%; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

#profile-picture {
    height: 100%;
    border: 5px solid #555;
    border-radius: 50%;
    box-shadow: 0px 0px 10px #777;
}

@media screen and (max-width: 850px) {
    #about-content-container {
        width: 100%;
        overflow: scroll;
    }
}   
